// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-benefits-js": () => import("./../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-career-js": () => import("./../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-catalog-js": () => import("./../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-privacy-js": () => import("./../src/pages/data-privacy.js" /* webpackChunkName: "component---src-pages-data-privacy-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-imprint-js": () => import("./../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-office-planning-check-costs-js": () => import("./../src/pages/office-planning/check-costs.js" /* webpackChunkName: "component---src-pages-office-planning-check-costs-js" */),
  "component---src-pages-office-planning-digital-office-management-js": () => import("./../src/pages/office-planning/digital-office-management.js" /* webpackChunkName: "component---src-pages-office-planning-digital-office-management-js" */),
  "component---src-pages-office-planning-floor-plan-js": () => import("./../src/pages/office-planning/floor-plan.js" /* webpackChunkName: "component---src-pages-office-planning-floor-plan-js" */),
  "component---src-pages-office-planning-index-js": () => import("./../src/pages/office-planning/index.js" /* webpackChunkName: "component---src-pages-office-planning-index-js" */),
  "component---src-pages-old-product-services-js": () => import("./../src/pages/old-product/services.js" /* webpackChunkName: "component---src-pages-old-product-services-js" */),
  "component---src-pages-old-product-services-our-services-js": () => import("./../src/pages/old-product/services/our-services.js" /* webpackChunkName: "component---src-pages-old-product-services-our-services-js" */),
  "component---src-pages-old-product-software-js": () => import("./../src/pages/old-product/software.js" /* webpackChunkName: "component---src-pages-old-product-software-js" */),
  "component---src-pages-product-js": () => import("./../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-references-index-js": () => import("./../src/pages/references/index.js" /* webpackChunkName: "component---src-pages-references-index-js" */),
  "component---src-pages-tos-js": () => import("./../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

